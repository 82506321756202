import React, { useContext, useEffect, useState } from 'react';
import Layout from '../../../components/Layout';
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import TextWithDivider from '../../../components/TextWithDivider';
import { AuthContext } from '../../../components/AuthContextProvider';
import SecondaryLayout from '../../../components/SecondaryLayout';
import TableWrapper from '../components/TableWrapper';
import useFetch from '../../../hooks/useFetch';
import { getRagData, updateRAGStatus } from '../../../operations/manager';
import { RAG_VALUE, STATUS_TEXT } from '../../../utils/utils';
import CommentDialog from '../../../components/CommentDialog';
import ConfirmationDialog from '../../../components/ConfirmationDialog';
import { colors } from '../../../theme/colors';
import { CheckCircleOutline } from '@material-ui/icons';
import { getAllActivitiesOnSheet } from '../../../helper/backendHelper/process';
import Message from '../../../components/Message';
import TableAssignRag from './TableAssignRag';
import CustomTabs from '../../../components/CustomTabs';
import { EventContext } from '../../../components/EventContextProvider';
import {
  getSheetByCollectionEventId,
  handleApproveRejectActivity,
} from '../../../helper/backendHelper/sheet';
import SelectEvents from '../../../components/SelectEvents';

const AssignRAG = () => {
  const { user } = useContext(AuthContext);

  const [resetSelected, setResetSelected] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedIds, setSelectedIds] = useState([]);
  const [comment, setComment] = useState('');
  const [ragValue, setRagValue] = useState('');
  const [commentDialogOpen, setCommentDialogOpen] = useState({
    isComment: false,
    value: '',
  });
  const [isConfirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [allActivities, setAllActivities] = useState([]);
  const [event, setEvent] = useState(null);
  const [actionType, setActionType] = useState('individual');

  const fetchAllActivitiesOnSheet = async (data, name) => {
    try {
      setActionType(name);
      setLoading(true);
      const response = await getSheetByCollectionEventId(data._id, name);
      setAllActivities(response?.payload?.data);

      setLoading(false);
    } catch (error) {
      setLoading(false);
      Message.error(error?.response?.data?.message);
    }
  };

  const {
    data: ragStatus = [],
    loading: ragLoading,
    fetchData,
  } = useFetch(getRagData, {
    teamId: user.teamId,
  });

  // const isActionable = (row) => {
  //   return true;
  // };

  const handleRagAssignClick = (Ids = [], val = '') => {
    setSelectedIds(Ids);
    setRagValue(val);
    if ([RAG_VALUE.RED, RAG_VALUE.AMBER].includes(val)) {
      setCommentDialogOpen(true);
    } else {
      setConfirmDialogOpen(true);
    }
  };

  const assignRag = async (comment = '') => {
    setLoading(true);
    if ([RAG_VALUE.GREEN].includes(ragValue)) {
      comment = '';
    }
    setConfirmDialogOpen(false);
    setCommentDialogOpen(false);
    await updateRAGStatus({
      activityIds: selectedIds,
      ragValue,
      comment,
      userId: user.uid,
    });
    setResetSelected(true);
    await fetchData({ teamId: user.teamId });
    setLoading(false);
    setResetSelected(false);
    setSelectedIds([]);
    setRagValue('');
  };

  //* handle Reject
  const handleApproveReject = async (managerApprove, workloadStatus) => {
    try {
      const response = await handleApproveRejectActivity({
        ids: selectedIds,
        status: workloadStatus,
        managerApprovalStatus: managerApprove,
        ...(commentDialogOpen.value == STATUS_TEXT.APPROVED
          ? { [user.role.toLowerCase() + 'ApprovalComment']: comment }
          : { [user.role.toLowerCase() + 'RejectedComment']: comment }),
      });
      Message.success(response.message);
      fetchAllActivitiesOnSheet(event, actionType);
      setSelectedIds([]);
      setCommentDialogOpen(false);
    } catch (error) {
      Message.error(error?.response?.data?.message);
    }
  };

  //* if is in progress
  const isActionable = (data) => {
    return data?.status === STATUS_TEXT.SUBMITTED;
  };

  return (
    <Layout loading={ragLoading || loading}>
      <SecondaryLayout>
        <Grid container className="pl-4 pr-2">
          <Grid item lg={9} md={8} className="pr-4 pt-4">
            <TextWithDivider>
              <Typography variant="h3" color="textPrimary">
                Assign RAG
              </Typography>
            </TextWithDivider>
            <Grid className="mt-4">
              {ragStatus.length && ragStatus.every((e) => !!e.ragValue) ? (
                <Typography align="right" className="my-2">
                  All assigned
                  <CheckCircleOutline
                    className="mx-2"
                    style={{ fontSize: 14, color: colors.success.main }}
                  />
                </Typography>
              ) : (
                ''
              )}

              <SelectEvents
                handleChangeEvents={fetchAllActivitiesOnSheet}
                setEvent={setEvent}
                actionType={actionType}
              />

              <Grid className="mt-3">
                <CustomTabs
                  setActionType={setActionType}
                  handleChangeTabWrapper={fetchAllActivitiesOnSheet}
                  event={event}
                  individualIdenticalWrap={true}
                  tabs={[
                    {
                      label: 'Individual',
                      children: (
                        <TableAssignRag
                          data={allActivities}
                          setCommentDialogOpen={setCommentDialogOpen}
                          isActionable={isActionable}
                          selected={selectedIds}
                          event={event}
                          isApproveReject={true}
                          setSelected={setSelectedIds}
                          handleApproveReject={handleApproveReject}
                          id={'AssignRAGTable'}
                          tableHeads={[
                            { id: 'name', label: 'Name' },
                            {
                              id: 'subProcessCount',
                              label: 'No Of Sub Process',
                            },
                            { id: 'activityCount', label: 'No Of Activities' },
                            { id: 'status', label: 'workload Status' },
                            {
                              id: 'managerApprovalStatus',
                              label: 'Manager Approval Status',
                              align: 'center',
                            },
                            {
                              id: 'managerSubmissionStatus',
                              label: 'Manager Submission Status',
                              align: 'center',
                            },
                            {
                              id: 'pocApprovalStatus',
                              label: 'Poc Approval Status',
                              align: 'center',
                            },
                            ...(event?.isManagerExists
                              ? [
                                  {
                                    id: 'comment',
                                    label: 'Poc Comment',
                                    align: 'center',
                                  },
                                ]
                              : [{}]),

                            {
                              id: 'actions',
                              label: 'Actions',
                            },
                          ]}
                          dataKeys={[
                            { id: 'name' },
                            { id: 'subProcessCount' },
                            { id: 'activityCount' },
                            { id: 'status' },
                            { id: 'managerApprovalStatus' },
                            { id: 'managerSubmissionStatus' },
                            { id: 'pocApprovalStatus' },
                            ...(event?.isManagerExists
                              ? [{ id: 'comment' }]
                              : [{}]),
                          ]}
                          checkId={'_id'}
                          resetSelected={resetSelected}
                          variant={'tableLong'}
                          disabled={loading}
                        />
                      ),
                    },
                    {
                      label: 'Identical',
                      children: (
                        <TableAssignRag
                          data={allActivities}
                          setCommentDialogOpen={setCommentDialogOpen}
                          isActionable={isActionable}
                          selected={selectedIds}
                          event={event}
                          isApproveReject={true}
                          setSelected={setSelectedIds}
                          handleApproveReject={handleApproveReject}
                          id={'AssignRAGTable'}
                          tableHeads={[
                            { id: 'name', label: 'Name' },
                            {
                              id: 'subProcessCount',
                              label: 'No Of Sub Process',
                            },
                            { id: 'activityCount', label: 'No Of Activities' },
                            { id: 'status', label: 'workload Status' },
                            {
                              id: 'managerApprovalStatus',
                              label: 'Manager Approval Status',
                            },
                            {
                              id: 'managerSubmissionStatus',
                              label: 'Manager Submission Status',
                            },
                            {
                              id: 'pocApprovalStatus',
                              label: 'Poc Approval Status',
                            },

                            ...(event?.isManagerExists
                              ? [
                                  {
                                    id: 'comment',
                                    label: 'Comment',
                                  },
                                ]
                              : [{}]),

                            {
                              id: 'actions',
                              label: 'Actions',
                            },
                          ]}
                          dataKeys={[
                            { id: 'name' },
                            { id: 'subProcessCount' },
                            { id: 'activityCount' },
                            { id: 'status' },
                            { id: 'managerApprovalStatus' },
                            { id: 'managerSubmissionStatus' },
                            { id: 'pocApprovalStatus' },
                            { id: 'comment' },
                          ]}
                          checkId={'_id'}
                          // isActionable={isActionable}
                          resetSelected={resetSelected}
                          variant={'tableLong'}
                          disabled={loading}
                        />
                      ),
                    },
                  ]}
                />
              </Grid>
            </Grid>

            <CommentDialog
              open={commentDialogOpen.isComment}
              setComment={setComment}
              comment={comment}
              handleClose={() => {
                setCommentDialogOpen(false);
                setSelectedIds([]);
                setRagValue('');
              }}
              handleSubmit={() => {
                if (commentDialogOpen.value == STATUS_TEXT.APPROVED) {
                  handleApproveReject(
                    STATUS_TEXT.APPROVED,
                    STATUS_TEXT.SUBMITTED
                  );
                } else {
                  handleApproveReject(
                    STATUS_TEXT.REJECTED,
                    STATUS_TEXT.REJECTED
                  );
                }
              }}
              title="Add comment"
            />
          </Grid>
        </Grid>
      </SecondaryLayout>
    </Layout>
  );
};

export default AssignRAG;
